<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="">
                        <div class="">
                          <div class="row">
                            <div class="col-xl-12">
                              <div class="product-detail">
                                <div class="row">
                                  <div class="col-md-12 col-2">
                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product1"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1572419535_%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product2"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1572419549_2.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product3"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1572419555_3.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product4"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1572419558_4.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product5"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1572419562_5.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="wehed()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1572419535_%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="thnine()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1572419549_2.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="tletha()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1572419555_3.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="arb3a()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1572419558_4.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="khamssa()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1572419562_5.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- end product img -->
                            </div>
                          </div>
                          <!-- end row -->
                        </div>
                      </div>
                      <!-- end card -->
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="mt-1 col-11">
                    <div class="row">
                      <h1 class="font-size-48 mb-3">
                        <strong>
                          High precision glue dispensing machine TG40
                        </strong>
                      </h1>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Brand :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >D&H</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Model :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >TG-40</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Applications :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >Capacitor, power supply, transformers, ignition coil, ignition coil, ballast and other products which need to be glued</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Suitable Material :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >Two-component liquid ,Epoxy, PU</span
                        >
                      </h5>
                    </div>
                    
                   
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 450px; height: 250px"
                            title="YouToube Video" 
                            src="https://www.youtube.com/embed/uZpUH1FAhx0"  
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <h1 style="color: black; font-weight: 700">Features</h1>
                        <br />

                        <div class="row" style="font-size: 14px">
                          1、 High speed and high efficiency
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          2、Easy to use
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          3、Desktop design, easy to place
                        </div>
                        <br />
                        </div>
                      <!-- end col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <br /><br />

            <br />
            <b-tabs pills card style="font-size: 18px">
              <b-tab active title="Technical Data">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <img src="../../../../assets/images/product/main/production_equipement/Glue_Dispensing_Machine/4tg40.jpg" alt="">
                  </div>
                </div>
              </b-tab>
              <b-tab  title="The Working Principle">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    Liquid A & B are kept in two tanks separately. There are two sets of metering pumps inside the machine, draining out liquids individually from tank A & B. Liquid A & B mix with each other in the mixer pipe and dispensed out according to the programmable data.
                    <img
                      src="https://v4-upload.goalsites.com/141/editor_1529630830_%E5%9B%BE%E7%89%871.png"
                      alt="img-1"
                      class="img-fluid mx-auto d-block rounded"
                    />
                  </div>
                </div>
              </b-tab>
              <b-tab  title="Other Information">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                  <ol>
                  <li> Pump adopts single cylinder plunger pump, A pump capacity is 40ml, B pump capacity is 4ml;</li>
                  <li>Tank/ pump / valve are connected directly, there is no pipeline connection between pump and valve, 3.Precision is high, 40g error is positive and negative 0.05g;</li>
                  <li>Weighing module is configured to detect the actual glue output of AB and realize closed loop control;</li>
                  <li>Pump outlet is at the bottom of pump cavity, it is easy to clean the whole pump completely when the pump is not removed</li>
                  </ol>
                   </div>
                  </div>
                
              </b-tab>
            </b-tabs>
            <br /><br />
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  data() {
    return {
      product1: true,
      product2: false,
      product3: false,
      product4: false,
      product5: false,
    };
  },
  components: {
    Header,

    Footer,
  },
  methods: {
    wehed() {
      (this.product1 = true),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = false),
        (this.product5 = false);
    },
    thnine() {
      (this.product1 = false),
        (this.product2 = true),
        (this.product3 = false),
        (this.product4 = false);
      this.product5 = false;
    },
    tletha() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = true),
        (this.product4 = false);
      this.product5 = false;
    },
    arb3a() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = true);
      this.product5 = false;
    },
    khamssa() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = false);
      this.product5 = true;
    },

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>